import React from 'react';
function Spinner() {
  return (
    <div className="loadingio-spinner-spinner-ogtoj99obmc"><div className="ldio-6a8hy7czdd6">
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div></div>
  );
}
export default Spinner;


